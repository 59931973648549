import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/suspect/Documents/coding/dbs-dresden/src/componets/mdxTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <title>Datenschutzerklärung</title>
    <h1>{`Datenschutzerklärung`}</h1>
    <p>{`Diese Seite erhebt und speichert keine personenbezogenen Daten. Eine Eingabe personenbezogener Daten auf dieser Seite und allen Unterseiten ist nicht möglich. Es werden keinerlei Nutzerdaten und Nutzerstatistiken während des Aufenthaltes auf der Seite erfasst oder gespeichert.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      